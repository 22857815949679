import logo from "./media/logo.png";
import "./App.css";
import Navbar from "./Navbar";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";

export default function App() {
  return (
    <div
      className="App fade-in"
      id="app"
      data-bs-toggle="collapse"
      data-bs-target="#navbarCollapse.show"
    >
      <div className="container text-dark center" id="container">
        <Navbar />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Deborah Adadewa</h1>
          <p className="header-p">
            Front-End Developer, Photographer &amp; Art Enthusiast
          </p>
        </header>
        <About />
        <Work />
        <Contact />
        <footer>
          <p>"whatever you do, work at it with all your heart"</p>

          <p>
            &copy;Made by{" "}
            <a href="https://github.com/deborahadadewa/" className="App-link">
              {" "}
              Deborah Adadewa
            </a>
            {". "}
            Hand cursor icom from{" "}
            <a href="https://icons8.com/" className="App-link">
              Icons8.com
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}
