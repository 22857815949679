import React from "react";
import "./Work.css";
import WebProject from "./WebProject";
import DictionaryImage from "./media/dictionary.png";
import WeatherReact from "./media/cinnamonweather.png";
import Kloudy from "./media/kloudy.png";

export default function Work() {
  return (
    <section className="Work text-content-lavendar" id="work">
      <h2>Work</h2>

      <h5>Web Projects:</h5>

      <div className="web container row">
        <div className="col-sm-4">
          <WebProject
            name="Cinnamon Weather"
            image={WeatherReact}
            link="https://cinnamonweather.netlify.app"
            description="A remake of the Kloudy app to compare React vs pure JavaScript. Please don't mind the theme...I was craving cinnamon tea when I made this."
          />
        </div>
        <div className="col-sm-4">
          <WebProject
            name="Image Dictionary"
            image={DictionaryImage}
            link="https://imagedictionary.netlify.app"
            description="A dictionary web app that shows images of what you are looking for in addition to the definition."
          />
        </div>

        <div className="col-sm-4">
          <WebProject
            name="Kloudy"
            image={Kloudy}
            link="https://kloudy.netlify.app"
            description="A weather app using JavaScript"
          />
        </div>
      </div>

      <h5 className="logofolio-title">Logofolio:</h5>
      <div className="logofolio container row px-0">
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img
              src="public-media/RoseCookiesDesign.jpg"
              alt="Rose Cookies Design Logo"
            />
          </div>
        </div>
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img
              src="public-media/AwosKitchenWhitebg.jpg"
              alt="Awo's Kitchen Logo"
            />
          </div>
        </div>
        <div className="col-sm-4 logo-e padding-0">
          <div className="outline-purple logo-container">
            <img src="public-media/mylogo.jpg" alt="Deborah Adadewa Logo" />
          </div>
        </div>
      </div>
    </section>
  );
}
